import { DocViewerComponent } from './doc-viewer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as i0 from "@angular/core";
export class DocumentModule {
}
DocumentModule.ɵmod = i0.ɵɵdefineNgModule({ type: DocumentModule });
DocumentModule.ɵinj = i0.ɵɵdefineInjector({ factory: function DocumentModule_Factory(t) { return new (t || DocumentModule)(); }, providers: [], imports: [[CommonModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(DocumentModule, { declarations: [DocViewerComponent], imports: [CommonModule], exports: [DocViewerComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(DocumentModule, [{
        type: NgModule,
        args: [{
                imports: [CommonModule],
                exports: [DocViewerComponent],
                declarations: [
                    DocViewerComponent
                ],
                providers: [],
            }]
    }], null, null); })();
