import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
export class IconRegistry {
    constructor() {
        this.registry = new Map();
    }
    registerIcons(icons) {
        icons.forEach((icon) => this.registry.set(icon.name, icon.data));
    }
    getIcon(iconName) {
        if (!this.registry.has(iconName)) {
            console.warn(`Icon with name ${iconName} was not found. Have you added it to your registry?`);
        }
        return this.registry.get(iconName);
    }
}
IconRegistry.ɵfac = function IconRegistry_Factory(t) { return new (t || IconRegistry)(); };
IconRegistry.ɵprov = i0.ɵɵdefineInjectable({ token: IconRegistry, factory: IconRegistry.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IconRegistry, [{
        type: Injectable
    }], function () { return []; }, null); })();
