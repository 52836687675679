import { LayoutModule } from '../../../webface/src/lib/layout/layout.module';

import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from '../environments/environment.prod';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// import { MatIconModule, MatIconRegistry } from '@angular/material/icon';

import {
  Location, LocationStrategy, PathLocationStrategy
} from '@angular/common';

import {
  DocumentService,
  DocumentModule,
  Logger,
  Environment,
  LocationService,
  NavigationService,
  ScrollService,
  ScrollSpyService,
  TocService,
  CustomElementsModule,
  ElementsLoader,
  ELEMENT_MODULE_LOAD_CALLBACKS,
  ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN,
  WindowToken,
  windowProvider,
  GaService,
  // SVG_ICONS,
  // CustomIconRegistry
} from '../../../webface/src/public-api';

import { RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ROUTES } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';

export const ELEMENT_MODULE_LOAD_CALLBACKS_AS_ROUTES = [
  {selector: 'sunfinity-carousel', loadChildren: () => import('../../../webface/src/lib/custom-elements/carousel/carousel.module').then(mod => mod.CarouselModule)},
  {selector: 'sunfinity-contributors', loadChildren: () => import('../../../webface/src/lib/custom-elements/contributors/contributors.module').then(mod => mod.ContributorsModule)},
  {selector: 'sunfinity-contact', loadChildren: () => import('../../../webface/src/lib/custom-elements/contact/contact.module').then(mod => mod.ContactModule)},
  {selector: 'sun-icon', loadChildren: () => import('../../../webface/src/lib/custom-elements/icons/icon.module').then(mod => mod.IconModule)},
  {selector: 'sun-blog-list', loadChildren: () => import('../../../webface/src/lib/custom-elements/blog/blog.module').then(mod => mod.BlogListModule)
  },
];

ELEMENT_MODULE_LOAD_CALLBACKS_AS_ROUTES.forEach(route => {
  ELEMENT_MODULE_LOAD_CALLBACKS.set(route.selector, route.loadChildren);
});


const materials = [
  MatSidenavModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatButtonModule,
  MatIconModule
];


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    DocumentModule,
    LayoutModule,
    CustomElementsModule,
    BrowserAnimationsModule,
    ...materials
  ],
  providers: [
    DocumentService,
    Logger,
    Location,
    { provide: Environment, useValue: environment },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    NavigationService,
    ScrollService,
    ScrollSpyService,
    TocService,
    { provide: RECAPTCHA_SETTINGS, useValue: { siteKey: environment.recaptcha_key } as RecaptchaSettings },
    LocationService,
    ElementsLoader,
    { provide: ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN, useValue: ELEMENT_MODULE_LOAD_CALLBACKS },
    { provide: ROUTES, useValue: ELEMENT_MODULE_LOAD_CALLBACKS_AS_ROUTES, multi: true },
    { provide: WindowToken, useFactory: windowProvider },
    GaService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
