import { Component, Input } from '@angular/core';

@Component({
  selector: 'webface-nav-menu',
  template: `
    <ul>
      <li *ngFor="let item of items">
        <a [href]="item.url" [title]="item.title" class="nav-link">
          <span class="nav-link-inner">{{item.title}}</span>
        </a>
      </li>
    </ul>
  `,
})

export class NavMenuComponent {
  @Input() items: any;

}
