import { DocViewerComponent } from './doc-viewer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule],
  exports: [DocViewerComponent],
  declarations: [
    DocViewerComponent
  ],
  providers: [],
})
export class DocumentModule { }
