import { IconRegistry } from './icon-registry';
import { NgModule, Type } from '@angular/core';
import { IconComponent } from './icon.component';
import { CommonModule } from '@angular/common';

import { hamburgerIcon } from './icons';

@NgModule({
  imports: [CommonModule],
  exports: [IconComponent],
  declarations: [IconComponent],
  providers: [IconRegistry]
})
export class IconModule {
  constructor(private registry: IconRegistry) {
    this.registry.registerIcons([
      hamburgerIcon
    ]);
  }
}
