import { LazyCustomElementComponent } from './lazy-custom-elements.component';
import { NgModule } from '@angular/core';

// import { ElementsLoader, ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN, ELEMENT_MODULE_LOAD_CALLBACKS } from './elements-loader';

// export { ElementsLoader, ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN, ELEMENT_MODULE_LOAD_CALLBACKS };

@NgModule({
  imports: [],
  exports: [
    LazyCustomElementComponent
  ],
  declarations: [
    LazyCustomElementComponent
  ],
  providers: [],
})
export class CustomElementsModule { }
