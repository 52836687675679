import { map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { interval } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/common";
function CarouselComponent_ng_container_1_img_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "img", 4);
} if (rf & 2) {
    const slide_r10 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("src", slide_r10.image, i0.ɵɵsanitizeUrl)("@carouselAnimation", undefined);
} }
function CarouselComponent_ng_container_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CarouselComponent_ng_container_1_img_1_Template, 1, 2, "img", 3);
    i0.ɵɵelementContainerEnd();
} if (rf & 2) {
    const i_r11 = ctx.index;
    const ctx_r9 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", i_r11 === ctx_r9.activeIndex);
} }
const _c0 = ["*"];
const slidesPath = 'generated/data/slides.json';
export class CarouselComponent {
    constructor(http) {
        this.http = http;
        this.activeIndex = 0;
    }
    ngOnInit() {
        this.http.get(slidesPath).pipe(map(response => response)).subscribe(slides => this.preloadImages(slides));
        interval(8000).subscribe(() => this.pushNextSlide());
    }
    preloadImages(slides) {
        this.slides = slides;
        for (const slide of this.slides) {
            new Image().src = slide.image;
        }
    }
    pushNextSlide() {
        const next = this.activeIndex + 1;
        this.activeIndex = next === this.slides.length ? 0 : next;
        // console.log("next slide is", this.activeIndex);
    }
}
CarouselComponent.ɵfac = function CarouselComponent_Factory(t) { return new (t || CarouselComponent)(i0.ɵɵdirectiveInject(i1.HttpClient)); };
CarouselComponent.ɵcmp = i0.ɵɵdefineComponent({ type: CarouselComponent, selectors: [["carousel"]], ngContentSelectors: _c0, decls: 4, vars: 1, consts: [[1, "webface-carousel"], [4, "ngFor", "ngForOf"], [1, "slide-content"], ["class", "webface-carousel-img", 3, "src", 4, "ngIf"], [1, "webface-carousel-img", 3, "src"]], template: function CarouselComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵtemplate(1, CarouselComponent_ng_container_1_Template, 2, 1, "ng-container", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵprojection(3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.slides);
    } }, directives: [i2.NgForOf, i2.NgIf], styles: ["[_nghost-%COMP%]{-webkit-box-flex:1;flex:1}.webface-carousel[_ngcontent-%COMP%]{position:relative;height:100%;width:100%;overflow:hidden;background:-webkit-gradient(linear,left top,left bottom,from(rgba(0,0,0,.5)));background:linear-gradient(rgba(0,0,0,.5))}.webface-carousel-img[_ngcontent-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;-o-object-fit:cover;object-fit:cover;z-index:-1}.slide-content[_ngcontent-%COMP%]{position:absolute;width:100%;height:100%}"], data: { animation: [
            trigger('carouselAnimation', [
                transition('void => *', [
                    style({ opacity: 0 }),
                    animate('500ms', style({ opacity: 1 }))
                ]),
                transition('* => void', [
                    animate('500ms', style({ opacity: 0 }))
                ])
            ])
        ] } });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CarouselComponent, [{
        type: Component,
        args: [{
                selector: 'carousel',
                template: `
    <div class="webface-carousel">

      <ng-container *ngFor="let slide of slides; let i = index">
        <img *ngIf="i === activeIndex" [src]="slide.image" class="webface-carousel-img" @carouselAnimation>
      </ng-container>

      <div class="slide-content">
        <ng-content></ng-content>
      </div>

    </div>

  `,
                styleUrls: ['./carousel.scss'],
                animations: [
                    trigger('carouselAnimation', [
                        transition('void => *', [
                            style({ opacity: 0 }),
                            animate('500ms', style({ opacity: 1 }))
                        ]),
                        transition('* => void', [
                            animate('500ms', style({ opacity: 0 }))
                        ])
                    ])
                ]
            }]
    }], function () { return [{ type: i1.HttpClient }]; }, null); })();
