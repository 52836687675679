import { ContribCardComponent } from './contrib-card.component';
import { CommonModule } from '@angular/common';
import { ContributerBioComponent } from './bio.component';
import { NgModule } from '@angular/core';
import { ContributorsComponent } from './contributors.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ContributorsService } from './contributors.service';
import * as i0 from "@angular/core";
export class ContributorsModule {
    constructor() {
        this.customElementComponent = ContributorsComponent;
    }
}
ContributorsModule.ɵmod = i0.ɵɵdefineNgModule({ type: ContributorsModule });
ContributorsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function ContributorsModule_Factory(t) { return new (t || ContributorsModule)(); }, providers: [ContributorsService], imports: [[
            CommonModule,
            MatDialogModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ContributorsModule, { declarations: [ContributorsComponent, ContributerBioComponent, ContribCardComponent], imports: [CommonModule,
        MatDialogModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContributorsModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    MatDialogModule
                ],
                declarations: [ContributorsComponent, ContributerBioComponent, ContribCardComponent],
                entryComponents: [ContributorsComponent],
                providers: [ContributorsService],
            }]
    }], null, null); })();
