import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CarouselComponent } from './carousel.component';
import * as i0 from "@angular/core";
export class CarouselModule {
    constructor() {
        this.customElementComponent = CarouselComponent;
    }
}
CarouselModule.ɵmod = i0.ɵɵdefineNgModule({ type: CarouselModule });
CarouselModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CarouselModule_Factory(t) { return new (t || CarouselModule)(); }, imports: [[CommonModule, HttpClientModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CarouselModule, { declarations: [CarouselComponent], imports: [CommonModule, HttpClientModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CarouselModule, [{
        type: NgModule,
        args: [{
                imports: [CommonModule, HttpClientModule],
                declarations: [CarouselComponent],
                entryComponents: [CarouselComponent],
            }]
    }], null, null); })();
