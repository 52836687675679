
import { MatSidenav } from '@angular/material/sidenav';
import { Component, OnInit, ViewChild, HostListener, HostBinding } from '@angular/core';
import { DocumentService, ScrollService, DocumentContents, ThemeService, NavigationService, LocationService, CurrentNodes } from '@webface';
import { Observable, BehaviorSubject, combineLatest, fromEvent } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { first, throttleTime, map, pairwise, distinctUntilChanged, share, filter } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  currentDocument: DocumentContents;
  currentNodes: CurrentNodes = {};
  currentPath: string;
  darkMode$: Observable<boolean>;

  pageId: string;
  folderId: string;

  @HostBinding('class')
  hostClasses = '';

  @HostBinding('@.disabled')
  isStarting = true;
  isTransitioning = true;
  isFetching = false;
  private isFetchingTimeout: any;
  isSideBySide = false;
  private sideBySideWidth = 992;
  scrollDirection: 'Up' | 'Down';
  toolbarNodes = [];

  @ViewChild(MatSidenav, {static: true}) sidenav: MatSidenav;
  get sidenavOpened() {return false};
  get sidenavMode() {return 'side'};
  sidenavNodes = [];

  footerNodes = [];

  constructor(
    private docService: DocumentService,
    private themeService: ThemeService,
    private navigationService: NavigationService,
    private scrollService: ScrollService,
    private locationService: LocationService
  ) {}

  ngOnInit() {

    this.onResize(window.innerWidth);

    this.docService.currentDocument.subscribe(doc => this.currentDocument = doc);

    this.darkMode$ = this.themeService.darkMode$;

    this.locationService.currentPath.subscribe(path => {
      if (path === this.currentPath) {
        this.scrollService.scroll();
      } else {
        this.currentPath = path;
        clearTimeout(this.isFetchingTimeout);
        this.isFetchingTimeout = setTimeout(() => this.isFetching = true, 200);
      }
    });

    this.navigationService.currentNodes.subscribe(nodes => {
      this.currentNodes = nodes
    });

    this.navigationService.navigationViews.subscribe(views => {
      this.toolbarNodes = views.toolbar || [];
      this.sidenavNodes = views.sidenav || [];
      this.footerNodes = views.footer || [];
    })
    combineLatest([
      this.docService.currentDocument,
      this.navigationService.currentNodes,
    ]).pipe(first())
      .subscribe(() => this.updateShell());
  }

  onDocReady() {
    this.isTransitioning = true;
    clearTimeout(this.isFetchingTimeout);
    setTimeout(() => this.isFetching = false, 500);
  }

  onDocRemoved() {
    this.scrollService.removeStoredScrollInfo();
  }

  onDocInserted() {
    setTimeout(() => this.updateShell());
    this.scrollService.scrollAfterRender(500);
  }

  onDocRendered() {
    if (this.isStarting) {
      setTimeout(() => this.isStarting = false, 100);
    }
    this.isTransitioning = false;
  }

  setDarkMode({ checked }: MatSlideToggleChange) {
    this.themeService.setDarkMode(checked);
  }

  @HostListener('window:resize', ['$event.target.innerWidth'])
  onResize(width: number) {
    this.isSideBySide = width >= this.sideBySideWidth;

    if (this.isSideBySide) {
      // If this is a non-sidenav doc and the screen is wide enough so that we can display menu
      // items in the top-bar, ensure the sidenav is closed.
      // (This condition can only be met when the resize event changes the value of `isSideBySide`
      //  from `false` to `true` while on a non-sidenav doc.)
      this.sidenav.toggle(false);
    }
  }

  @HostListener('click', ['$event.target', '$event.button', '$event.ctrlKey', '$event.metaKey', '$event.altKey'])
  onClick(eventTarget: HTMLElement, button: number, ctrlKey: boolean, metaKey: boolean): boolean {
    let target: HTMLElement|null = eventTarget;
    while (target && !(target instanceof HTMLAnchorElement)) {
      target = target.parentElement;
    }
    if (target instanceof HTMLAnchorElement) {
      return this.locationService.handleAnchorClick(target, button, ctrlKey, metaKey);
    }

    // Allow the click to pass through
    return true;
  }

  updateHostClasses() {
    // const mode = `mode-${this.deployment.mode}`;
    const sideNavOpen = `sidenav-${this.sidenav.opened ? 'open' : 'closed'}`;
    const pageClass = `page-${this.pageId}`;
    const folderClass = `folder-${this.folderId}`;
    // const viewClasses = Object.keys(this.currentNodes).map(view => `view-${view}`).join(' ');
    // const notificationClass = `aio-notification-${this.notification.showNotification}`;
    // const notificationAnimatingClass = this.notificationAnimating ? 'aio-notification-animating' : '';

    this.hostClasses = [
      // mode,
      sideNavOpen,
      pageClass,
      folderClass,
      // viewClasses,
      // notificationClass,
      // notificationAnimatingClass
    ].join(' ');
  }

  setPageId(id: string) {
    // Special case the home page
    this.pageId = (id === 'index') ? 'home' : id.replace('/', '-');
  }

  setFolderId(id: string) {
    // Special case the home page
    this.folderId = (id === 'index') ? 'home' : id.split('/', 1)[0];
  }

  updateShell() {
    // Update the SideNav state (if necessary).
    this.updateSideNav();
    this.toolbarOnScroll();

    // Update the host classes.
    this.setPageId(this.currentDocument.id);
    this.setFolderId(this.currentDocument.id);
    this.updateHostClasses();
  }

  toolbarOnScroll() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      map(([y1, y2]) => (y2 < y1 ? 'Up' : 'Down')),
      distinctUntilChanged(),
      share()
    );

    const goingUp$ = scroll$.pipe(
      filter(direction => direction === 'Up')
    );

    const goingDown$ = scroll$.pipe(
      filter(direction => direction === 'Down')
    );

    goingUp$.subscribe(() => (this.scrollDirection = 'Up'));
    goingDown$.subscribe(() => (this.sidenav.opened ? this.scrollDirection = 'Up' : this.scrollDirection = 'Down'));
  }

  updateSideNav() {
    let openSideNav = this.sidenav.opened;

    this.sidenav.toggle(
      this.isSideBySide &&
      openSideNav);
  }

}
