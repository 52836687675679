import { LazyCustomElementComponent } from './lazy-custom-elements.component';
import { NgModule } from '@angular/core';
import * as i0 from "@angular/core";
// import { ElementsLoader, ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN, ELEMENT_MODULE_LOAD_CALLBACKS } from './elements-loader';
// export { ElementsLoader, ELEMENT_MODULE_LOAD_CALLBACKS_TOKEN, ELEMENT_MODULE_LOAD_CALLBACKS };
export class CustomElementsModule {
}
CustomElementsModule.ɵmod = i0.ɵɵdefineNgModule({ type: CustomElementsModule });
CustomElementsModule.ɵinj = i0.ɵɵdefineInjector({ factory: function CustomElementsModule_Factory(t) { return new (t || CustomElementsModule)(); }, providers: [], imports: [[]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(CustomElementsModule, { declarations: [LazyCustomElementComponent], exports: [LazyCustomElementComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(CustomElementsModule, [{
        type: NgModule,
        args: [{
                imports: [],
                exports: [
                    LazyCustomElementComponent
                ],
                declarations: [
                    LazyCustomElementComponent
                ],
                providers: [],
            }]
    }], null, null); })();
