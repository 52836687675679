import { map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
const slidesPath = 'generated/data/slides.json';

export interface Slide {
  image: string;
  visible: boolean;
}

@Component({
  selector: 'carousel',
  template: `
    <div class="webface-carousel">

      <ng-container *ngFor="let slide of slides; let i = index">
        <img *ngIf="i === activeIndex" [src]="slide.image" class="webface-carousel-img" @carouselAnimation>
      </ng-container>

      <div class="slide-content">
        <ng-content></ng-content>
      </div>

    </div>

  `,
  styleUrls: ['./carousel.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 }))
      ]),
      transition('* => void', [
        animate('500ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CarouselComponent implements OnInit{
  slides: Slide[];
  activeIndex = 0;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.http.get<Slide[]>(slidesPath).pipe(
      map(response => response)
    ).subscribe(
      slides => this.preloadImages(slides)
    );
    interval(8000).subscribe(() => this.pushNextSlide());
  }

  preloadImages(slides: Slide[]) {
    this.slides = slides;
    for (const slide of this.slides) {
      new Image().src = slide.image;
    }
  }

  pushNextSlide() {
    const next = this.activeIndex + 1;
    this.activeIndex = next === this.slides.length ? 0 : next;
    // console.log("next slide is", this.activeIndex);
  }

}
