import { ContributorsService } from './contributors.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contributors',
  template: `

    <contrib-card *ngFor="let contrib of contribs" [contrib]="contrib"></contrib-card>

  `,
})

export class ContributorsComponent implements OnInit {
  contribs: any;

  constructor(
    private contribService: ContributorsService
  ) { }

  ngOnInit() {
    this.contribService.contributers$.subscribe(
      data => this.contribs = data
    );
  }
}

// style="width: 300px; height: 450px; display: flex; flex-direction: column; justify-content: space-evenly; margin: 1em;"
