import { publishLast, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class ContributorsService {
    constructor(http) {
        this.http = http;
        this.contributers$ = this.getContributers();
    }
    getContributers() {
        const contribs = this.http.get('generated/data/leadership.json')
            .pipe(map(r => r), publishLast());
        contribs.connect();
        return contribs;
    }
}
ContributorsService.ɵfac = function ContributorsService_Factory(t) { return new (t || ContributorsService)(i0.ɵɵinject(i1.HttpClient)); };
ContributorsService.ɵprov = i0.ɵɵdefineInjectable({ token: ContributorsService, factory: ContributorsService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContributorsService, [{
        type: Injectable
    }], function () { return [{ type: i1.HttpClient }]; }, null); })();
