import { LayoutComponent } from './layout.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { FooterComponent } from './footer/footer.component';

import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';


@NgModule({
  imports: [
    CommonModule,
    MatSidenavModule,
    MatToolbarModule
  ],
  exports: [
    LayoutComponent,
    TopMenuComponent,
    NavMenuComponent,
    FooterComponent,
    ToolbarComponent,
    SidenavComponent
  ],
  declarations: [
    LayoutComponent,
    TopMenuComponent,
    NavMenuComponent,
    FooterComponent,
    ToolbarComponent,
    SidenavComponent
  ],
  providers: [],
})
export class LayoutModule { }
