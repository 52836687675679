import { __awaiter } from "tslib";
import { Injectable, Inject } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import * as i0 from "@angular/core";
export class ThemeService {
    constructor(rendererFactory, document) {
        this._mainTheme$ = new BehaviorSubject('theme-default');
        this._darkMode$ = new BehaviorSubject(false);
        this.darkMode$ = this._darkMode$.asObservable();
        this.themeLinks = [];
        this.head = document.head;
        this._renderer = rendererFactory.createRenderer(null, null);
        this.theme$ = combineLatest(this._mainTheme$, this._darkMode$);
        this.theme$.subscribe(([mainTheme, darkMode]) => __awaiter(this, void 0, void 0, function* () {
            const cssExt = '.css';
            const cssFilename = darkMode ? mainTheme + '-dark' + cssExt : mainTheme + cssExt;
            yield this.loadCss(cssFilename);
            if (this.themeLinks.length == 2)
                this._renderer.removeChild(this.head, this.themeLinks.shift());
        }));
    }
    setMainTheme(name) {
        this._mainTheme$.next(name);
    }
    setDarkMode(value) {
        this._darkMode$.next(value);
    }
    loadCss(filename) {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise(resolve => {
                const linkEl = this._renderer.createElement('link');
                this._renderer.setAttribute(linkEl, 'rel', 'stylesheet');
                this._renderer.setAttribute(linkEl, 'type', 'text/css');
                this._renderer.setAttribute(linkEl, 'href', filename);
                this._renderer.setProperty(linkEl, 'onload', resolve);
                this._renderer.appendChild(this.head, linkEl);
                this.themeLinks = [...this.themeLinks, linkEl];
            });
        });
    }
}
ThemeService.ɵfac = function ThemeService_Factory(t) { return new (t || ThemeService)(i0.ɵɵinject(i0.RendererFactory2), i0.ɵɵinject(DOCUMENT)); };
ThemeService.ɵprov = i0.ɵɵdefineInjectable({ token: ThemeService, factory: ThemeService.ɵfac, providedIn: 'root' });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ThemeService, [{
        type: Injectable,
        args: [{
                providedIn: 'root'
            }]
    }], function () { return [{ type: i0.RendererFactory2 }, { type: undefined, decorators: [{
                type: Inject,
                args: [DOCUMENT]
            }] }]; }, null); })();
