import { Environment } from './../../shared/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface Contact {
  first_name: string;
  last_name: string;
  business: string;
  email: string;
  phone: string;
  message: string;
  recaptcha_token: string;
  company: string;
}

@Injectable()
export class ContactFormService {

  constructor(
    private http: HttpClient,
    private env: Environment
  ) { }

  postContact(contact: Contact): Observable<Contact> {
    return this.http.post<Contact>(`${this.env.endpoint}/contactus`, contact);
  }

}
