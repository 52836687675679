import { ContactFormService } from './contact-form.service';
import { CommonModule } from '@angular/common';
import { WithCustomElementComponent } from '../elements-loader';
import { NgModule, Type } from '@angular/core';

import { ContactComponent } from './contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    HttpClientModule
  ],
  exports: [],
  declarations: [ContactComponent],
  entryComponents: [ContactComponent],
  providers: [ContactFormService],
})
export class ContactModule implements WithCustomElementComponent {
  customElementComponent: Type<any> = ContactComponent;
}
