import { distinctUntilChanged, take, debounceTime, debounce } from 'rxjs/operators';
import { Environment } from '../../shared/environment';
import { LocationService } from '../../shared/location.service';
import { Component, OnInit, ViewChild, ElementRef, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactFormService } from './contact-form.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { stringify } from 'querystring';
import { RecaptchaComponent } from 'ng-recaptcha';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subscription, timer } from 'rxjs';

export interface Contact {
  id: string;
  full_name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  message: string;
  subject: string;
  type: string;
  recaptchaKey: string;
}

@Component({
  selector: 'contact-form',
  template: `
    <form [formGroup]="contactForm">

      <div class="row">
        <mat-form-field appearance="outline" class="col">
          <input matInput placeholder="First Name" formControlName="first_name">
        </mat-form-field>

        <mat-form-field appearance="outline" class="col">
          <input matInput placeholder="Last Name" formControlName="last_name">
        </mat-form-field>
      </div>

      <div class="row">

      <mat-form-field appearance="outline" class="col">
        <input matInput placeholder="Email" formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col">
        <input matInput placeholder="Phone" formControlName="phone">
      </mat-form-field>

      </div>

      <div class="row">
      <mat-form-field appearance="outline" class="col">
        <input matInput placeholder="Subject" formControlName="subject">
      </mat-form-field>

      </div>
      <div class="row">
      <mat-form-field appearance="outline" class="col">
        <textarea matInput placeholder="Message" formControlName="message"></textarea>
      </mat-form-field>
      </div>
      <div class='row'>
        <re-captcha size="invisible" #invisible formControlName="recaptcha_token" (resolved)="$event && submit()"></re-captcha>
      </div>

      <div class="row" style="padding: 15px">
        <button class="sun-square-btn theme-background text-primary" (click)="invisible.execute()">SUBMIT</button>
      </div>


    </form>

  `
})

export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  type: string;
  @ViewChild('invisible', {static: true}) invisible: RecaptchaComponent;

  constructor(
    private fb: FormBuilder,
    private contactService: ContactFormService,
    private locService: LocationService,
    private environment: Environment,
    private snackbar: MatSnackBar
  ) { }

  ngOnInit() {
    this.locService.currentUrl.subscribe(path => this.type = path);

    this.contactForm = this.fb.group({
      first_name: ['', [Validators.maxLength(20), Validators.required]],
      last_name: ['', [Validators.maxLength(20), Validators.required]],
      email: ['', [Validators.required, Validators.maxLength(50)]],
      phone: ['', [Validators.required, Validators.maxLength(25)]],
      subject: ['', [Validators.required, Validators.maxLength(100)]],
      message: ['', [Validators.required, Validators.maxLength(255)]],
      recaptcha_token: ['', Validators.required],
      company: ['', Validators.maxLength(50)]
    });

    this.contactForm.patchValue({company: `${this.environment.endpoint}/companies/sun`}, {onlySelf: true});
  }

  openSnackbar(message: string) {
    this.snackbar.open(message, '', {
      duration: 5000
    });
  }

  submit() {
    const success = 'Thank you for your submission. We will contact you as soon as possible.';
    const error = 'There seems to have been a problem. Please check your response for errors.';
    const contact = this.contactForm.value;
    if ( this.contactForm.valid ) {
      this.contactService.postContact(contact).subscribe(res => console.log(res));
      this.openSnackbar(success);
      this.reset(this.contactForm);
    } else if (!this.contactForm.valid) {
      this.openSnackbar(error);
    }
  }

  reset(form: FormGroup) {
    form.reset();

    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null) ;
    });
  }
}
