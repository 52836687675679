import { Component } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "./contributors.service";
import * as i2 from "@angular/common";
import * as i3 from "./contrib-card.component";
function ContributorsComponent_contrib_card_0_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelement(0, "contrib-card", 1);
} if (rf & 2) {
    const contrib_r6 = ctx.$implicit;
    i0.ɵɵproperty("contrib", contrib_r6);
} }
export class ContributorsComponent {
    constructor(contribService) {
        this.contribService = contribService;
    }
    ngOnInit() {
        this.contribService.contributers$.subscribe(data => this.contribs = data);
    }
}
ContributorsComponent.ɵfac = function ContributorsComponent_Factory(t) { return new (t || ContributorsComponent)(i0.ɵɵdirectiveInject(i1.ContributorsService)); };
ContributorsComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ContributorsComponent, selectors: [["contributors"]], decls: 1, vars: 1, consts: [[3, "contrib", 4, "ngFor", "ngForOf"], [3, "contrib"]], template: function ContributorsComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵtemplate(0, ContributorsComponent_contrib_card_0_Template, 1, 1, "contrib-card", 0);
    } if (rf & 2) {
        i0.ɵɵproperty("ngForOf", ctx.contribs);
    } }, directives: [i2.NgForOf, i3.ContribCardComponent], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContributorsComponent, [{
        type: Component,
        args: [{
                selector: 'contributors',
                template: `

    <contrib-card *ngFor="let contrib of contribs" [contrib]="contrib"></contrib-card>

  `,
            }]
    }], function () { return [{ type: i1.ContributorsService }]; }, null); })();
