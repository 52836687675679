import { Component, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "./contact-form.service";
import * as i3 from "../../shared/location.service";
import * as i4 from "../../shared/environment";
import * as i5 from "@angular/material/snack-bar";
import * as i6 from "@angular/material/form-field";
import * as i7 from "@angular/material/input";
import * as i8 from "ng-recaptcha";
const _c0 = ["invisible"];
export class ContactComponent {
    constructor(fb, contactService, locService, environment, snackbar) {
        this.fb = fb;
        this.contactService = contactService;
        this.locService = locService;
        this.environment = environment;
        this.snackbar = snackbar;
    }
    ngOnInit() {
        this.locService.currentUrl.subscribe(path => this.type = path);
        this.contactForm = this.fb.group({
            first_name: ['', [Validators.maxLength(20), Validators.required]],
            last_name: ['', [Validators.maxLength(20), Validators.required]],
            email: ['', [Validators.required, Validators.maxLength(50)]],
            phone: ['', [Validators.required, Validators.maxLength(25)]],
            subject: ['', [Validators.required, Validators.maxLength(100)]],
            message: ['', [Validators.required, Validators.maxLength(255)]],
            recaptcha_token: ['', Validators.required],
            company: ['', Validators.maxLength(50)]
        });
        this.contactForm.patchValue({ company: `${this.environment.endpoint}/companies/sun` }, { onlySelf: true });
    }
    openSnackbar(message) {
        this.snackbar.open(message, '', {
            duration: 5000
        });
    }
    submit() {
        const success = 'Thank you for your submission. We will contact you as soon as possible.';
        const error = 'There seems to have been a problem. Please check your response for errors.';
        const contact = this.contactForm.value;
        if (this.contactForm.valid) {
            this.contactService.postContact(contact).subscribe(res => console.log(res));
            this.openSnackbar(success);
            this.reset(this.contactForm);
        }
        else if (!this.contactForm.valid) {
            this.openSnackbar(error);
        }
    }
    reset(form) {
        form.reset();
        Object.keys(form.controls).forEach(key => {
            form.get(key).setErrors(null);
        });
    }
}
ContactComponent.ɵfac = function ContactComponent_Factory(t) { return new (t || ContactComponent)(i0.ɵɵdirectiveInject(i1.FormBuilder), i0.ɵɵdirectiveInject(i2.ContactFormService), i0.ɵɵdirectiveInject(i3.LocationService), i0.ɵɵdirectiveInject(i4.Environment), i0.ɵɵdirectiveInject(i5.MatSnackBar)); };
ContactComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ContactComponent, selectors: [["contact-form"]], viewQuery: function ContactComponent_Query(rf, ctx) { if (rf & 1) {
        i0.ɵɵstaticViewQuery(_c0, true);
    } if (rf & 2) {
        var _t;
        i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.invisible = _t.first);
    } }, decls: 23, vars: 1, consts: [[3, "formGroup"], [1, "row"], ["appearance", "outline", 1, "col"], ["matInput", "", "placeholder", "First Name", "formControlName", "first_name"], ["matInput", "", "placeholder", "Last Name", "formControlName", "last_name"], ["matInput", "", "placeholder", "Email", "formControlName", "email"], ["matInput", "", "placeholder", "Phone", "formControlName", "phone"], ["matInput", "", "placeholder", "Subject", "formControlName", "subject"], ["matInput", "", "placeholder", "Message", "formControlName", "message"], ["size", "invisible", "formControlName", "recaptcha_token", 3, "resolved"], ["invisible", ""], [1, "row", 2, "padding", "15px"], [1, "sun-square-btn", "theme-background", "text-primary", 3, "click"]], template: function ContactComponent_Template(rf, ctx) { if (rf & 1) {
        const _r8 = i0.ɵɵgetCurrentView();
        i0.ɵɵelementStart(0, "form", 0);
        i0.ɵɵelementStart(1, "div", 1);
        i0.ɵɵelementStart(2, "mat-form-field", 2);
        i0.ɵɵelement(3, "input", 3);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(4, "mat-form-field", 2);
        i0.ɵɵelement(5, "input", 4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(6, "div", 1);
        i0.ɵɵelementStart(7, "mat-form-field", 2);
        i0.ɵɵelement(8, "input", 5);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(9, "mat-form-field", 2);
        i0.ɵɵelement(10, "input", 6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(11, "div", 1);
        i0.ɵɵelementStart(12, "mat-form-field", 2);
        i0.ɵɵelement(13, "input", 7);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(14, "div", 1);
        i0.ɵɵelementStart(15, "mat-form-field", 2);
        i0.ɵɵelement(16, "textarea", 8);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(17, "div", 1);
        i0.ɵɵelementStart(18, "re-captcha", 9, 10);
        i0.ɵɵlistener("resolved", function ContactComponent_Template_re_captcha_resolved_18_listener($event) { return $event && ctx.submit(); });
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(20, "div", 11);
        i0.ɵɵelementStart(21, "button", 12);
        i0.ɵɵlistener("click", function ContactComponent_Template_button_click_21_listener($event) { i0.ɵɵrestoreView(_r8); const _r7 = i0.ɵɵreference(19); return _r7.execute(); });
        i0.ɵɵtext(22, "SUBMIT");
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵproperty("formGroup", ctx.contactForm);
    } }, directives: [i1.ɵangular_packages_forms_forms_y, i1.NgControlStatusGroup, i1.FormGroupDirective, i6.MatFormField, i7.MatInput, i1.DefaultValueAccessor, i1.NgControlStatus, i1.FormControlName, i8.RecaptchaComponent, i8.RecaptchaValueAccessorDirective], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContactComponent, [{
        type: Component,
        args: [{
                selector: 'contact-form',
                template: `
    <form [formGroup]="contactForm">

      <div class="row">
        <mat-form-field appearance="outline" class="col">
          <input matInput placeholder="First Name" formControlName="first_name">
        </mat-form-field>

        <mat-form-field appearance="outline" class="col">
          <input matInput placeholder="Last Name" formControlName="last_name">
        </mat-form-field>
      </div>

      <div class="row">

      <mat-form-field appearance="outline" class="col">
        <input matInput placeholder="Email" formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col">
        <input matInput placeholder="Phone" formControlName="phone">
      </mat-form-field>

      </div>

      <div class="row">
      <mat-form-field appearance="outline" class="col">
        <input matInput placeholder="Subject" formControlName="subject">
      </mat-form-field>

      </div>
      <div class="row">
      <mat-form-field appearance="outline" class="col">
        <textarea matInput placeholder="Message" formControlName="message"></textarea>
      </mat-form-field>
      </div>
      <div class='row'>
        <re-captcha size="invisible" #invisible formControlName="recaptcha_token" (resolved)="$event && submit()"></re-captcha>
      </div>

      <div class="row" style="padding: 15px">
        <button class="sun-square-btn theme-background text-primary" (click)="invisible.execute()">SUBMIT</button>
      </div>


    </form>

  `
            }]
    }], function () { return [{ type: i1.FormBuilder }, { type: i2.ContactFormService }, { type: i3.LocationService }, { type: i4.Environment }, { type: i5.MatSnackBar }]; }, { invisible: [{
            type: ViewChild,
            args: ['invisible', { static: true }]
        }] }); })();
