import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
export class ContribCardComponent {
    constructor() {
    }
    ngOnInit() { }
}
ContribCardComponent.ɵfac = function ContribCardComponent_Factory(t) { return new (t || ContribCardComponent)(); };
ContribCardComponent.ɵcmp = i0.ɵɵdefineComponent({ type: ContribCardComponent, selectors: [["contrib-card"]], inputs: { contrib: "contrib" }, decls: 7, vars: 3, consts: [[1, "contrib-card"], [1, "contrib-headshot", 3, "src"], [1, "contrib-info"]], template: function ContribCardComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "div", 0);
        i0.ɵɵelement(1, "img", 1);
        i0.ɵɵelementStart(2, "div", 2);
        i0.ɵɵelementStart(3, "h3");
        i0.ɵɵtext(4);
        i0.ɵɵelementEnd();
        i0.ɵɵelementStart(5, "p");
        i0.ɵɵtext(6);
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("src", ctx.contrib.image, i0.ɵɵsanitizeUrl);
        i0.ɵɵadvance(3);
        i0.ɵɵtextInterpolate(ctx.contrib.name);
        i0.ɵɵadvance(2);
        i0.ɵɵtextInterpolate(ctx.contrib.title);
    } }, encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContribCardComponent, [{
        type: Component,
        args: [{
                selector: 'contrib-card',
                template: `
    <div class="contrib-card">
      <img class="contrib-headshot" [src]="contrib.image">
      <div class="contrib-info">
        <h3>{{contrib.name}}</h3>
        <p>{{contrib.title}}</p>
      </div>
    </div>

  `
            }]
    }], function () { return []; }, { contrib: [{
            type: Input
        }] }); })();
