import { LayoutComponent } from './layout.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { FooterComponent } from './footer/footer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import * as i0 from "@angular/core";
export class LayoutModule {
}
LayoutModule.ɵmod = i0.ɵɵdefineNgModule({ type: LayoutModule });
LayoutModule.ɵinj = i0.ɵɵdefineInjector({ factory: function LayoutModule_Factory(t) { return new (t || LayoutModule)(); }, providers: [], imports: [[
            CommonModule,
            MatSidenavModule,
            MatToolbarModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(LayoutModule, { declarations: [LayoutComponent,
        TopMenuComponent,
        NavMenuComponent,
        FooterComponent,
        ToolbarComponent,
        SidenavComponent], imports: [CommonModule,
        MatSidenavModule,
        MatToolbarModule], exports: [LayoutComponent,
        TopMenuComponent,
        NavMenuComponent,
        FooterComponent,
        ToolbarComponent,
        SidenavComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(LayoutModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    MatSidenavModule,
                    MatToolbarModule
                ],
                exports: [
                    LayoutComponent,
                    TopMenuComponent,
                    NavMenuComponent,
                    FooterComponent,
                    ToolbarComponent,
                    SidenavComponent
                ],
                declarations: [
                    LayoutComponent,
                    TopMenuComponent,
                    NavMenuComponent,
                    FooterComponent,
                    ToolbarComponent,
                    SidenavComponent
                ],
                providers: [],
            }]
    }], null, null); })();
