import { Component, Input } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
function NavMenuComponent_li_1_Template(rf, ctx) { if (rf & 1) {
    i0.ɵɵelementStart(0, "li");
    i0.ɵɵelementStart(1, "a", 1);
    i0.ɵɵelementStart(2, "span", 2);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
    i0.ɵɵelementEnd();
} if (rf & 2) {
    const item_r2 = ctx.$implicit;
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("href", item_r2.url, i0.ɵɵsanitizeUrl)("title", item_r2.title);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(item_r2.title);
} }
export class NavMenuComponent {
}
NavMenuComponent.ɵfac = function NavMenuComponent_Factory(t) { return new (t || NavMenuComponent)(); };
NavMenuComponent.ɵcmp = i0.ɵɵdefineComponent({ type: NavMenuComponent, selectors: [["webface-nav-menu"]], inputs: { items: "items" }, decls: 2, vars: 1, consts: [[4, "ngFor", "ngForOf"], [1, "nav-link", 3, "href", "title"], [1, "nav-link-inner"]], template: function NavMenuComponent_Template(rf, ctx) { if (rf & 1) {
        i0.ɵɵelementStart(0, "ul");
        i0.ɵɵtemplate(1, NavMenuComponent_li_1_Template, 4, 3, "li", 0);
        i0.ɵɵelementEnd();
    } if (rf & 2) {
        i0.ɵɵadvance(1);
        i0.ɵɵproperty("ngForOf", ctx.items);
    } }, directives: [i1.NgForOf], encapsulation: 2 });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(NavMenuComponent, [{
        type: Component,
        args: [{
                selector: 'webface-nav-menu',
                template: `
    <ul>
      <li *ngFor="let item of items">
        <a [href]="item.url" [title]="item.title" class="nav-link">
          <span class="nav-link-inner">{{item.title}}</span>
        </a>
      </li>
    </ul>
  `,
            }]
    }], null, { items: [{
            type: Input
        }] }); })();
