import { IconRegistry } from './icon-registry';
import { NgModule } from '@angular/core';
import { IconComponent } from './icon.component';
import { CommonModule } from '@angular/common';
import { hamburgerIcon } from './icons';
import * as i0 from "@angular/core";
import * as i1 from "./icon-registry";
export class IconModule {
    constructor(registry) {
        this.registry = registry;
        this.registry.registerIcons([
            hamburgerIcon
        ]);
    }
}
IconModule.ɵmod = i0.ɵɵdefineNgModule({ type: IconModule });
IconModule.ɵinj = i0.ɵɵdefineInjector({ factory: function IconModule_Factory(t) { return new (t || IconModule)(i0.ɵɵinject(i1.IconRegistry)); }, providers: [IconRegistry], imports: [[CommonModule]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(IconModule, { declarations: [IconComponent], imports: [CommonModule], exports: [IconComponent] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(IconModule, [{
        type: NgModule,
        args: [{
                imports: [CommonModule],
                exports: [IconComponent],
                declarations: [IconComponent],
                providers: [IconRegistry]
            }]
    }], function () { return [{ type: i1.IconRegistry }]; }, null); })();
