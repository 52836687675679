import { NgModule, Type } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CarouselComponent } from './carousel.component';
import { WithCustomElementComponent } from '../elements-loader';

@NgModule({
  imports: [ CommonModule, HttpClientModule ],
  declarations: [ CarouselComponent ],
  entryComponents: [ CarouselComponent ],
})
export class CarouselModule implements WithCustomElementComponent {
  customElementComponent: Type<any> = CarouselComponent;
}
