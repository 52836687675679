import { ContactFormService } from './contact-form.service';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContactComponent } from './contact.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import * as i0 from "@angular/core";
export class ContactModule {
    constructor() {
        this.customElementComponent = ContactComponent;
    }
}
ContactModule.ɵmod = i0.ɵɵdefineNgModule({ type: ContactModule });
ContactModule.ɵinj = i0.ɵɵdefineInjector({ factory: function ContactModule_Factory(t) { return new (t || ContactModule)(); }, providers: [ContactFormService], imports: [[
            CommonModule,
            ReactiveFormsModule,
            MatFormFieldModule,
            MatInputModule,
            MatSnackBarModule,
            RecaptchaModule,
            RecaptchaFormsModule,
            HttpClientModule
        ]] });
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(ContactModule, { declarations: [ContactComponent], imports: [CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSnackBarModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        HttpClientModule] }); })();
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContactModule, [{
        type: NgModule,
        args: [{
                imports: [
                    CommonModule,
                    ReactiveFormsModule,
                    MatFormFieldModule,
                    MatInputModule,
                    MatSnackBarModule,
                    RecaptchaModule,
                    RecaptchaFormsModule,
                    HttpClientModule
                ],
                exports: [],
                declarations: [ContactComponent],
                entryComponents: [ContactComponent],
                providers: [ContactFormService],
            }]
    }], null, null); })();
