import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./../../shared/environment";
export class ContactFormService {
    constructor(http, env) {
        this.http = http;
        this.env = env;
    }
    postContact(contact) {
        return this.http.post(`${this.env.endpoint}/contactus`, contact);
    }
}
ContactFormService.ɵfac = function ContactFormService_Factory(t) { return new (t || ContactFormService)(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Environment)); };
ContactFormService.ɵprov = i0.ɵɵdefineInjectable({ token: ContactFormService, factory: ContactFormService.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(ContactFormService, [{
        type: Injectable
    }], function () { return [{ type: i1.HttpClient }, { type: i2.Environment }]; }, null); })();
