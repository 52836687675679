import { ErrorHandler, Injectable } from '@angular/core';
import { Environment } from './environment';

@Injectable()
export class Logger {

  constructor(
    private environment: Environment,
    private errorHandler: ErrorHandler
  ) {}

  log(value: any, ...rest: any[]) {
    if (this.environment.production === false) {
      console.log(value, ...rest);
    }
  }

  error(error: Error) {
    this.errorHandler.handleError(error);
  }

  warn(value: any, ...rest: any[]) {
    console.warn(value, ...rest);
  }
}
