
import { Injectable } from '@angular/core';
import { Icon } from './icons';

@Injectable()
export class IconRegistry {

  constructor() {}

  private registry = new Map<string, string>();

  public registerIcons(icons: Icon[]): void {
    icons.forEach((icon: Icon) => this.registry.set(icon.name, icon.data));
  }

  public getIcon(iconName: string): string | undefined {
    if (!this.registry.has(iconName)) {
      console.warn(
        `Icon with name ${iconName} was not found. Have you added it to your registry?`
      );
    }
    return this.registry.get(iconName);
  }

}
