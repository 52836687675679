import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'contrib-card',
  template: `
    <div class="contrib-card">
      <img class="contrib-headshot" [src]="contrib.image">
      <div class="contrib-info">
        <h3>{{contrib.name}}</h3>
        <p>{{contrib.title}}</p>
      </div>
    </div>

  `
})

export class ContribCardComponent implements OnInit {
  @Input() contrib: any;

  constructor() { }

  ngOnInit() { }
}
