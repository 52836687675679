<div id="top-of-page"></div>

<div *ngIf="isFetching" class="progress-bar-container">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<mat-toolbar class="toolbar" [ngClass]="scrollDirection" [class.transitioning]="isTransitioning">

  <mat-toolbar-row>
    <span>
<!--       <button mat-button class="hamburger d-md-none" (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <a class="nav-link home" href="/">
        <img src="assets/images/logos/sunfinity-icon-300.svg" title="Home" alt="Home">
      </a> -->

      <div class="nav-link home" [ngSwitch]="isSideBySide">
        <span *ngSwitchCase="true">
          <a class="nav-link home" href="/">
            <img src="assets/images/logos/sunfinity-logo-500.png" width="150" height="40" title="Home" alt="Home">
          </a>
        </span>
        <span *ngSwitchDefault>
          <button mat-button class="hamburger mr-1"  [class.starting]="isStarting" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
          <a class="nav-link home" href="/"><img src="assets/images/logos/sunfinity-icon-300.svg" title="Home" alt="Home"></a>
        </span>

      </div>
    </span>

    <span>
      <div class="d-none d-md-flex">
        <webface-nav-menu [items]="toolbarNodes"></webface-nav-menu>
      </div>
    </span>


  </mat-toolbar-row>

</mat-toolbar>

<mat-sidenav-container class="sidenav-container" [class.starting]="isStarting" role="main">

  <mat-sidenav [ngClass]="{'collapsed': !isSideBySide}" class="sidenav" #sidenav fixedInViewport="true" fixedTopGap="72" [opened]="sidenavOpened" [mode]="sidenavMode" (openedChange)="updateHostClasses()">

    <webface-nav-menu [items]="sidenavNodes"></webface-nav-menu>

    <hr>

    <mat-slide-toggle color="accent" [checked]="darkMode$ | async" (change)="setDarkMode($event)" class="ml-2 mt-2" style="font-family: Lato, 'sans-serif';">Dark Mode</mat-slide-toggle>

  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <main class="main" [id]="pageId" role="main">
      <!-- <div class="content"> -->
        <webface-doc-viewer [class.no-animations]="isStarting" [doc]="currentDocument" (docReady)="onDocReady()" (docRemoved)="onDocRemoved()" (docInserted)="onDocInserted()" (docRendered)="onDocRendered()"></webface-doc-viewer>
      <!-- </div> -->
    </main>
    <footer class="footer">
      <section class="accent-background">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <div>
                <div>
                  <img src="assets/images/logos/sunfinity-logo-500.png">
                  <p>Sunfinity is an industry leader in renewable energy solutions for residential, commercial and utility scale applications. Our customer service, solar expertise and flexible plans make Sunfinity the perfect energy partner for homeowners and businesses.</p>
                  <div class="d-flex social-icons">
                    <a href="https://www.facebook.com/Sunfinityre/"><img class="sm-icon mr-2" src="generated/images/icons/social/facebook.svg" alt="facebook"></a>
                    <a href="https://twitter.com/sunfinityre?lang=en"><img class="sm-icon mr-2" src="generated/images/icons/social/twitter.svg" alt="twitter"></a>
                    <!-- <a href=""><img class="sm-icon" src="generated/images/icons/social/instagram.svg" alt="instagram"></a > -->
                    <a href="https://www.youtube.com/channel/UCYL5EL3qwg2K17yc-0vO1lw"><img class="sm-icon mr-2" src="generated/images/icons/social/youtube.svg" alt="youtube"></a>
                    <a href="https://www.pinterest.com/sunfinityre/"><img class="sm-icon mr-2" src="generated/images/icons/social/pintrest.svg" alt="pintrest"></a>
                    <a href="https://www.linkedin.com/company/sunfinity-solar"><img class="sm-icon mr-2" src="generated/images/icons/social/linkedin.svg" alt="linkedin"></a>
                  </div>
                  <hr>
                  <p class="i">Proud Partners of:</p>
                  <div class="footer-associations">
                    <div>
                      <a href="https://www.seia.org/directory/sunfinity-renewable-energy"><img src="generated/images/SEIA.png"></a>
                    </div>
                    <div>
                      <img src="generated/images/texas-solar-power.png">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 footer-nav-menu">
              <div>
                <h5>NAVIGATION</h5>
                <webface-nav-menu [items]="footerNodes"></webface-nav-menu>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="primary-background">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d-flex justify-content-center">
              Sunfinity 2019 © All Rights Reserved | Terms of Use
            </div>
            <div class="col-md-6 d-flex justify-content-center">
              CA C10 CSLB – License #1013931 | TX License #TECL 32505
            </div>
          </div>
        </div>
      </section>
    </footer>
  </mat-sidenav-content>


</mat-sidenav-container>






<div class="cdk-visually-hidden" *ngIf="!isStarting">
  <mat-icon>&nbsp;</mat-icon>
</div>
