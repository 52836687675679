import { ContribCardComponent } from './contrib-card.component';
import { CommonModule } from '@angular/common';
import { ContributerBioComponent } from './bio.component';
import { NgModule, Type } from '@angular/core';

import { ContributorsComponent } from './contributors.component';
import { WithCustomElementComponent } from '../elements-loader';
import { MatDialogModule } from '@angular/material/dialog';
import { ContributorsService } from './contributors.service';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule
  ],
  declarations: [ContributorsComponent, ContributerBioComponent, ContribCardComponent],
  entryComponents: [ContributorsComponent],
  providers: [ContributorsService],
})
export class ContributorsModule implements WithCustomElementComponent {
  customElementComponent: Type<any> = ContributorsComponent;
}
