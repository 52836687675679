import { Injectable } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "./environment";
export class Logger {
    constructor(environment, errorHandler) {
        this.environment = environment;
        this.errorHandler = errorHandler;
    }
    log(value, ...rest) {
        if (this.environment.production === false) {
            console.log(value, ...rest);
        }
    }
    error(error) {
        this.errorHandler.handleError(error);
    }
    warn(value, ...rest) {
        console.warn(value, ...rest);
    }
}
Logger.ɵfac = function Logger_Factory(t) { return new (t || Logger)(i0.ɵɵinject(i1.Environment), i0.ɵɵinject(i0.ErrorHandler)); };
Logger.ɵprov = i0.ɵɵdefineInjectable({ token: Logger, factory: Logger.ɵfac });
/*@__PURE__*/ (function () { i0.ɵsetClassMetadata(Logger, [{
        type: Injectable
    }], function () { return [{ type: i1.Environment }, { type: i0.ErrorHandler }]; }, null); })();
