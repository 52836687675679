import { publishLast, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ConnectableObservable } from 'rxjs';

@Injectable()
export class ContributorsService {

  contributers$: Observable<any[]>;

  constructor(private http: HttpClient) {
    this.contributers$ = this.getContributers();
  }

  getContributers(): Observable<any[]> {
    const contribs = this.http.get('generated/data/leadership.json')
      .pipe(
        map(r => r),
        publishLast()
      );

    (contribs as ConnectableObservable<any[]>).connect();
    return contribs
  }

}
